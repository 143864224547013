<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
            >
              Adaugă task
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="filter in taskFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-3 px-2 d-flex justify-content-between align-items-center">
              <h6 class="section-label mb-0">
                Etichete
              </h6>
              <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      @click="showModal">
                Adaugă
              </b-button>
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="tag in taskTags"
                :key="tag.name"
                :to="tag.route.route"
                :active="isDynamicRouteActive(tag.route.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :style="tag.color ? 'background-color: ' + tag.color : 'background-color: black'"
                />
                <span>{{ tag.name }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
    <b-modal
            v-model="modalShow"
            id="modal-login"
            cancel-variant="outline-secondary"
            ok-title="Salvează"
            @ok="saveTag"
            cancel-title="Închide"
            centered
            title="Adaugă Etichetă"
    >
      <b-form>
        <b-form-group>
          <label for="name">Nume etichetă</label>
          <b-form-input
                  v-model="tag.name"
                  id="name"
                  type="text"
                  placeholder="Nume etichetă"
          />
        </b-form-group>
        <label for="name">Culoare etichetă</label>
        <color-picker v-model="tag.color" id="name"></color-picker>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axiosIns from '../../../libs/axios'
import ColorPicker from './ColorPicker'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem, BFormInput, BModal, BFormGroup, BForm } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    ColorPicker,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormInput,
    BModal,
    BFormGroup,
    BForm,
    VuePerfectScrollbar,
  },
  props: {
    taskTags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalShow: false,
      tag:{
        'color': null,
        'name': null
      }
    }
  },
  methods:{
    showModal(){
      this.modalShow = true;
    },
    saveTag(){
      axiosIns.post("/tag-save", this.tag)
              .then(response => {
                this.modalShow = false;
                this.$events.emit('loadTagList');
              })
              .catch(e => {
                this.errors.push(e);
                this.modalShow = false;
              })
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const taskFilters = [
      { title: 'Toate taskurile', icon: 'MailIcon', route: { name: 'apps-todo' } },
      { title: 'De făcut', icon: 'Edit3Icon', route: { name: 'apps-todo-filter', params: { filter: 'todo' } } },
      { title: 'Importante', icon: 'StarIcon', route: { name: 'apps-todo-filter', params: { filter: 'important' } } },
      { title: 'Finalizate', icon: 'CheckCircleIcon', route: { name: 'apps-todo-filter', params: { filter: 'completed' } } },
    ]

    return {
      perfectScrollbarSettings,
      taskFilters,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
