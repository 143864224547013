<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <b-button
            v-if="taskLocal.id"
            size="sm"
            :variant="
              taskLocal.isCompleted ? 'outline-success' : 'outline-secondary'
            "
            @click="taskLocal.isCompleted = !taskLocal.isCompleted"
          >
            {{ taskLocal.isCompleted ? 'Finalizat' : 'Finalizare task' }}
          </b-button>
          <h5 v-else class="mb-0">Adaugă task</h5>
          <div>
            <feather-icon
              v-show="taskLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="
                $emit('remove-task')
                hide()
              "
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="StarIcon"
              size="16"
              :class="{ 'text-warning': taskLocal.isImportant }"
              @click="updateTaskIsImportant(taskLocal)"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="Title"
              rules="required"
            >
              <b-form-group label="Titlu" label-for="task-title">
                <b-form-input
                  id="task-title"
                  v-model="taskLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Titlul taskului"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- due Date -->
            <validation-provider #default="validationContext" name="Due Date">
              <b-form-group label="Termen limită" label-for="due-date">
                <flat-pickr v-model="taskLocal.date" :config="config" placeholder="Select date" class="form-control" />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--Tag -->
            <b-form-group label="Etichete" label-for="tag">
              <v-select
                v-model="taskLocal.tags"
                dir="ltr"
                multiple
                :close-on-select="false"
                :options="taskTags"
                label="name"
                :reduce="(option) => option.name"
                input-id="tags"
              />
            </b-form-group>

            <!-- Description -->
            <b-form-group label="Descriere" label-for="task-description">
              <quill-editor
                id="quil-content"
                v-model="taskLocal.description"
                :options="editorOption"
                class="border-bottom-0"
              />
              <div
                id="quill-toolbar"
                class="d-flex justify-content-end border-top-0"
              >
                <!-- Add a bold button -->
                <button class="ql-bold" />
                <button class="ql-italic" />
                <button class="ql-underline" />
                <button class="ql-align" />
                <button class="ql-link" />
              </div>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2 space-between">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="store()"
              >
                {{ taskLocal.id ? 'Modifică task' : 'Adaugă task' }}
              </b-button>
              <b-button
                v-if="taskLocal.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="submit"
                @click="
                  deleteTask(taskLocal.id)
                  hide()
                "
              >
                Sterge
              </b-button>
              <b-button
                v-if="!taskLocal.id"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Resetează
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import axiosIns from '../../../libs/axios'
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BAvatar,
  BButton,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import useTaskHandler from './useTaskHandler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
    taskTags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      loading: false,
      config: {
          wrap: true, // set wrap to true only when using 'input-group'
            altInput: true,
           altFormat: "d/m/Y",
          locale: {
            firstDayOfWeek: 1,
         }    
        },               
    }
  },
  methods: {
    deleteTask(id) {
      axiosIns
        .post('/todo-delete', { id: id })
        .then((response) => {
          this.$events.emit('loadTaskList')
          this.showToast('primary', 'Success', 'Taskul a fost sters!')
        })
        .catch((error) => {})
    },
    store() {
      axiosIns
        .post('/todo-save', this.taskLocal)
        .then((response) => {
          this.$events.emit('loadTaskList', this.$route.params)
          this.showToast('primary', 'Success', 'Taskul a fost adaugat!')
        })
        .catch((error) => {})
    },
    showToast(variant, title, msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: 'BellIcon',
          text: msg,
          variant,
        },
      })
    },
  },
  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      onSubmit,
      tagOptions,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearTaskData)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Descrierea taskului',
    }

    const updateTaskIsImportant = (taskData) => {
      if(taskData.id) {
        axiosIns
                .post('/todo-important', taskData)
                .then((response) => {
                  fetchTasks()
                })
                .catch((error) => {})
      }
      taskData.isImportant = !taskData.isImportant
    }

    return {
      // Add New
      taskLocal,
      onSubmit,
      tagOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,

      updateTaskIsImportant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
