import axios from '@axios'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx, payload) {},
    addTask(ctx, taskData) {},
    updateTask(ctx, { task }) {},
    removeTask(ctx, { id }) {},
  },
}
